<template>
  <div class="manageBox">
    <div class="top">
      <el-form ref="form" :inline="true" :model="queryParams" label-width="">
        <el-form-item label="类型名称">
          <el-input
            style="width: 230px"
            placeholder="请输入类型名称"
            clearable
            v-model="queryParams.keyWord"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
        </el-form-item>
        <el-button style="float: right" type="primary" @click="toAddOrEdit"
          >新增</el-button
        >
      </el-form>
    </div>
    <div class="content">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :row-style="{ height: '55px' }"
        style="width: 100%"
        :header-cell-style="headerCellStyle"
        class="content-itme"
      >
        <template slot="empty">
          <IsEmpty />
        </template>
        <el-table-column prop="name" align="center" label="类型名称" />
        <el-table-column
          prop="serviceContent"
          align="center"
          label="服务内容及补贴标准"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.serviceContent">
              <div v-if="scope.row.serviceContent.includes('1')">
                助老服务({{ scope.row.elderlySubsidy }}元/月)
              </div>
              <div v-if="scope.row.serviceContent.includes('2')">
                信息服务({{ scope.row.informationSubsidy }}元/月)
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <span class="operation" @click="toDetail(scope.row)">查看</span>
            <span class="operation" @click="toAddOrEdit(scope.row)">编辑</span>
            <span class="operation stop" @click="delItem(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin: 20px 0"
        background
        layout="total, sizes, prev, pager, next"
        :total="total"
        :page-size="queryParams.pageSize"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <addOrEditObjTypeDilog
      :editRow="editRow"
      @updateList="getList"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
    />
    <serveDetail
      v-if="serveDetailVisible"
      :editRow="editRow"
      :serveDetailVisible.sync="serveDetailVisible"
    />
  </div>
</template>

<script>
import addOrEditObjTypeDilog from './addOrEditObjTypeDilog'
import serveDetail from './serviceDetail'
import { getListData, delitem } from '@/api/homeCasedCareServices'
export default {
  components: {
    addOrEditObjTypeDilog,
    serveDetail
  },
  props: {
    activeType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      editRow: {},
      dialogVisible: false,
      serveDetailVisible: false,
      total: 0,
      loading: false,
      options: [],
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyWord: ''
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /*查看*/
    toDetail(row) {
      this.editRow = row
      this.serveDetailVisible = true
    },
    toAddOrEdit(row) {
      if (row && row.id) {
        this.editRow = row
      } else {
        this.editRow = {
          name: '',
          informationSubsidy: 0, //信息补贴
          elderlySubsidy: 0, //助老补贴
          elderlySubsidyChecked: false,
          informationSubsidyChecked: false
        }
      }
      this.dialogVisible = true
    },
    async getList() {
      this.loading = true
      const res = await getListData(this.queryParams)
      if (res.state === 0) {
        this.loading = false
        this.tableData = res.data.rows
        this.tableData.forEach((item) => {
          item.serviceContent = item.serviceContent.split(',')
        })
        this.total = res.data.total
      }
    },
    /*删除*/
    delItem(row) {
      this.$confirm(`此操作将删除"${row.name}"服务对象, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delitempost(row.id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async delitempost(id) {
      const res = await delitem({ id: id })
      if (res.state === 0) {
        this.$message.success(res.data)
        this.getList()
      }
    },
    // 翻页
    handleCurrentChange(val) {
      this.loading = true
      this.queryParams.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.loading = true
      this.queryParams.pageSize = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.manageBox {
  // margin: 20px;
  background: #fff;
  min-height: calc(100vh - 130px);
  .eyes {
    // display: inline-block;
    width: 20px;
    height: 20px;
    float: right;
    margin-right: 20px;
    cursor: pointer;
  }
  .operation {
    margin-right: 20px;
    color: #3fcf89;
    cursor: pointer;
  }
  .stop {
    color: #f57473;
  }
  .widthStyle {
    width: 90%;
  }
}
.content {
  /*padding: 15px;*/
  padding-top: 20px;
}
.my_btn_duty {
  margin-right: 20px;
}
.el-button--text {
  outline: none;
  border: none;
}
.el-button--text:hover,
.el-button--text:focus {
  background: none !important;
  border: none !important;
  outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
  color: #c0c4cc !important;
}
.w200px {
  width: 200px;
}
</style>
