<template>
  <el-dialog
    :title="editRow.id ? '编辑': '新增'"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose">
    <div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="类型名称" prop="name">
          <el-input style="width: 80%" clearable maxlength="20" show-word-limit v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="服务内容及补贴标准" prop="mycontent">
          <el-form-item label="" prop="elderlySubsidyChecked">
            <el-checkbox v-model="ruleForm.elderlySubsidyChecked">助老服务</el-checkbox>
            <span v-if="ruleForm.elderlySubsidyChecked"  class="ml30px">补贴 <el-input-number v-model="ruleForm.elderlySubsidy" :min="0" :max="99999"/> 元/月</span>
          </el-form-item>
          <el-form-item label="" prop="informationSubsidyChecked">
            <el-checkbox v-model="ruleForm.informationSubsidyChecked" value="2">信息服务</el-checkbox>
            <span v-if="ruleForm.informationSubsidyChecked" class="ml30px">补贴 <el-input-number v-model="ruleForm.informationSubsidy" :min="0" :max="99999" /> 元/月</span>
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
  import { addService, editService } from "@/api/homeCasedCareServices"
  export default {
    name: "addOrEditObjTypeDilog",
    props: {
      dialogVisible: {
        type: Boolean,
        default: false
      },
      editRow: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        ruleForm: {
          name: '',
          informationSubsidy: 0, //信息补贴
          elderlySubsidy: 0, //助老补贴
          elderlySubsidyChecked: false,
          informationSubsidyChecked: false
        },
        rules: {
          name: [
            { required: true, message: '请输入类型名称', trigger: 'blur' },
            {max: 20, message: '最长长度20', trigger: 'blur' }
          ],
          mycontent: [{
              validator: (rule, value, callback) => {
                if (!this.ruleForm.elderlySubsidyChecked && !this.ruleForm.informationSubsidyChecked) {
                  callback(new Error('请勾选服务内容及补贴标准'))
                } else {
                  callback()
                }
              },
              trigger: ['blur', 'change'],
              required: true
            }
          ]
        }
      }
    },
    watch: {
      'ruleForm.elderlySubsidyChecked': {
        deep:true,
        handler(val) {
          if(val) {
            if(!this.ruleForm.id)   this.$refs['ruleForm'].clearValidate(['mycontent'])

          }
        }
      },
      'ruleForm.informationSubsidyChecked': {
        deep:true,
        handler(val) {
          if(val) {
            if(!this.ruleForm.id)   this.$refs['ruleForm'].clearValidate(['mycontent'])
          }
        }
      }
    },
    mounted() {
      if(this.editRow.serviceContent) {
        if(this.editRow.serviceContent.includes('1')) {
          this.ruleForm.elderlySubsidyChecked = true
        }else {
          this.ruleForm.elderlySubsidyChecked = false
        }
        if(this.editRow.serviceContent.includes('2')) {
          this.ruleForm.informationSubsidyChecked = true
        }else {
          this.ruleForm.informationSubsidyChecked = false
        }
      }

      this.ruleForm.id = this.editRow.id
      this.ruleForm.informationSubsidy = this.editRow.informationSubsidy //信息补贴
      this.ruleForm.elderlySubsidy =this.editRow.elderlySubsidy//助老补贴
      this.ruleForm.name =this.editRow.name//助老补贴
    },
    methods: {
      handleClose() {
        this.$emit('update:dialogVisible', false)
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = JSON.parse(JSON.stringify(this.ruleForm))
            let serviceContent = []
            if(params.elderlySubsidyChecked) {
              serviceContent.push(1)
            }
            if(params.informationSubsidyChecked) {
              serviceContent.push(2)
            }
            params.serviceContent = serviceContent.join(',')
            Reflect.deleteProperty(params, 'elderlySubsidyChecked')
            Reflect.deleteProperty(params, 'informationSubsidyChecked')
            if(this.ruleForm.id) {
              editService(params).then(res => {
                if(res.state === 0) {
                  this.$message.success(res.data)
                  this.$emit('updateList')
                }
                this.handleClose()
              })
            }else {
              addService(params).then(res => {
                if(res.state === 0) {
                  this.$message.success(res.data)
                  this.$emit('updateList')
                }
                this.handleClose()
              })
            }

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>

<style scoped>
  .ml30px {
    display: inline-block;
    margin-left: 30px;
  }
</style>
